<template>
	<div class="line" ref="line"></div>
</template>

<script>
import echarts from 'echarts';
export default {
	props: {
		sugarData: {
			type: Array,
			default: () => {
				return [];
			},
		},
	},
	methods: {
		initChart() {
			let sugarData = [];
			if (this.sugarData[3] && this.sugarData[4]) {
				sugarData = [];
				let index3Data = (this.sugarData[2] * 1 + this.sugarData[3] * 1) / 2;
				let index6Data = (this.sugarData[3] * 1 + this.sugarData[4] * 1) / 2;
				sugarData = [
					this.sugarData[0],
					this.sugarData[1],
					this.sugarData[2],
					`${index3Data}`,
					this.sugarData[3],
					`${index6Data}`,
					this.sugarData[4],
				];
			} else if (this.sugarData[3]) {
				sugarData = [];
				let index3Data = (this.sugarData[2] * 1 + this.sugarData[3] * 1) / 2;
				sugarData = [
					this.sugarData[0],
					this.sugarData[1],
					this.sugarData[2],
					index3Data,
					this.sugarData[3],
				];
			} else {
				sugarData = this.sugarData;
			}
			// echarts实例化
			this.Charts = echarts.init(this.$refs.line);
			var options = {
				title: {
					text: 'OGTT胰岛功能曲线',
					textStyle: {
						color: '#297ddf',
					},
					left: 'center',
				},
				tooltip: {
					trigger: 'axis',
					formatter: function (params) {
						let res;
						if (params.length == 2) {
							res = `${params[0].seriesName}:${params[0].data}<br>${params[1].seriesName}:${params[1].data}`;
						}
						if (params.length == 1) {
							res = params[0].seriesName + ':' + params[0].data;
						}
						if (params[0].dataIndex != 3 && params[0].dataIndex != 5) {
							return res;
						}
					},
				},
				legend: {
					data: ['实测血糖', '肾糖阈'],
					bottom: 0,
				},
				color: ['#297ddf', '#ff9008'],
				grid: {
					left: '3%',
					right: '4%',
					bottom: '10%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					axisLine: {
						lineStyle: {
							color: '#297ddf',
						},
					},
					axisTick: {
						show: false,
					},
					boundaryGap: false,
					data: ['空腹', '0.5h', '1h', '', '2h', '', '3h'],
				},
				yAxis: {
					type: 'value',
					name: 'mmol/L',
					axisLine: {
						lineStyle: {
							color: '#297ddf',
						},
					},
					splitLine: {
						show: false,
					},
					data: [3.9, 11],
				},
				series: [
					{
						name: 'limit1',
						type: 'line',
						stack: '总量',
						areaStyle: {
							color: '#fffaed',
						},
						symbol: 'none',
						data: new Array(7).fill(3.9),
						tooltip: { show: false },
						lineStyle: {
							color: '#fffaed',
						},
					},
					{
						name: 'limit2',
						type: 'line',
						stack: '总量',
						areaStyle: {
							color: '#e9fce3',
						},
						symbol: 'none',
						data: new Array(7).fill(6.1 - 3.9),
						tooltip: { show: false },
						lineStyle: {
							color: '#e9fce3',
						},
					},
					{
						name: 'limit3',
						type: 'line',
						stack: '总量',
						areaStyle: {
							color: '#eff3fd',
						},
						symbol: 'none',
						data: new Array(7).fill(7.8 - 6.1),
						tooltip: { show: false },
						lineStyle: {
							color: '#eff3fd',
						},
					},
					{
						name: 'limit4',
						type: 'line',
						stack: '总量',
						areaStyle: {
							color: '#fff2e9',
						},
						symbol: 'none',
						data: new Array(7).fill(11.1 - 7.8),
						tooltip: { show: false },
						lineStyle: {
							color: '#fff2e9',
						},
					},
					{
						name: 'limit5',
						type: 'line',
						stack: '总量',
						areaStyle: {
							color: '#ffdbdb',
						},
						symbol: 'none',
						data: new Array(7).fill(15 - 11.1),
						tooltip: { show: false },
						lineStyle: {
							color: '#ffdbdb',
						},
					},
					{
						name: '实测血糖',
						type: 'line',
						data: sugarData,
						symbol: 'none',
					},
					{
						name: '肾糖阈',
						type: 'line',
						lineStyle: {
							type: 'dotted',
						},
						symbol: function (value, i) {
							let arr = [3, 5];
							if (arr.indexOf(i.dataIndex) > -1) {
								return 'none';
							}
						},
						data: [6.1, 7.8, 8.9, 8.9, 8.9, 7.5, 6.1], // 多出来的第三项和第五项取它左右的平均值 没有意义 只为展示
					},
				],
			};
			this.Charts.setOption(options);
		},
	},
	mounted() {
		this.initChart();
	},
	watch: {
		sugarData() {
			this.initChart();
		},
	},
};
</script>

<style>
	.line {
		width: 100%;
		height: 320px;
	}
</style>
