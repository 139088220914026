<template>
  <div class="quantify-container">
    <header class="header">
      <div class="header-title">
        <div class="header-title--left">
          <div class="header-img">
            <img src="@/assets/img/quantify-header.png" />
          </div>
          <div>
            <p class="name-ch">{{ nameCh }}</p>
            <p class="name-en">{{ nameEn }}</p>
          </div>
        </div>
        <div class="header-title--right">
          <p class="title-branch">门店：{{ userInfo.md }}</p>
          <p class="title-date">
            <span>报告日期：{{ userInfo.date }}</span>
            <!-- <span>作业日期：{{ userInfo.trainStartTime }}-{{ userInfo.trainEndTime }}</span> -->
          </p>
        </div>
      </div>
      <div class="divider divider__bold" style="margin-bottom: 5px"></div>
      <div class="divider divider__normal"></div>
      <div class="header-info">
        <div class="header-info--left">
          <div class="header-img">
            <img src="@/assets/img/quantify-avatar.png" />
          </div>
          <div>
            <p class="name-ch">基础信息</p>
            <p class="name-en">BASIC INFORMATION</p>
          </div>
        </div>
        <div class="header-info--right">
          <table class="table">
            <tr>
              <th>姓名</th>
              <th>性别</th>
              <th>年龄</th>
            </tr>
            <tr style="font-weight: bold;">
              <td>{{ userInfo.name }}</td>
              <td>{{ userInfo.sex_show }}</td>
              <td>{{ userInfo.age }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="divider divider__dashed mb20"></div>
    </header>
    <section class="section">
      <slot class="content-panel"></slot>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		nameCh: {
			type: String,
			default: '能量摄入管理',
		},
		nameEn: {
			type: String,
			default: 'ENERGY INTAKE MANAGEMENT',
		},
	},
	computed: {
		...mapGetters({ userInfo: 'reportUserData' }),
	},
};
</script>

<style lang="less" scoped>
.quantify-container {
  background-color: #fff;
  width: 1100px;
  min-height: 1665px;
  padding: 15px 20px 0;
  display: flex;
  flex-direction: column;
}
.header {
  color: #005ab3;
  .header-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .header-img {
      height: 85px;
      img {
        height: 100%;
      }
    }
    &--left {
      display: flex;
      .name-ch {
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 5px;
      }
      .name-en {
        font-size: 22px;
      }
    }
    &--right {
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .title-branch {
        font-size: 22px;
        font-weight: bold;
      }
      .title-date {
        font-size: 16px;
        font-weight: bold;
        span {
          margin-left: 20px;
        }
      }
    }
  }
  .header-info {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    .header-img {
      height: 42px;
      margin: 5px 10px 0 8px;
      img {
        height: 100%;
      }
    }
    &--left {
      display: flex;
      margin: auto 0;
      .name-ch {
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 5px;
      }
      .name-en {
        font-size: 14px;
      }
    }
    &--right {
      .table {
        width: 400px;
        table-layout: fixed;
        text-align: center;
      }
      th {
        background-color: #005ab3;
        color: #fff;
      }
      th,
      td {
        height: 30px;
        border: #efefef solid 1px;
      }
    }
  }
  .header-disease {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: bold;
    &--left {
      font-weight: bold;
    }
    .table {
      width: 100%;
      table-layout: fixed;
      text-align: center;
      font-size: 16px;
      &-title {
        background-color: #005ab3;
        border: #005ab3 solid 1px;
        color: #fff;
        width: 80px;
        height: 61px;
        line-height: 61px;
        text-align: center;
      }
      td {
        height: 30px;
        border: #005ab3 solid 1px;
        &:nth-child(even) {
          background-color: #e9f4ff;
        }
      }
    }
  }
}
.section {
  flex: 1;
}
.divider {
  background-color: #005ab3;
  width: 100%;
  &__bold {
    height: 3px;
  }
  &__normal {
    height: 1px;
  }
  &__dashed {
    background-color: transparent;
    border-bottom: dashed 1px #005ab3;
  }
}
</style>
